import React, { Component } from 'react'
import Helmet from 'react-helmet'
import ThemeContext from '../context/ThemeContext'
import Layout from '../layout'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'

export default class NotFoundPage extends Component {
  static contextType = ThemeContext

  componentDidMount() {
    const { setNotFound } = this.context

    setNotFound()
  }

  componentWillUnmount() {
    const { setFound } = this.context

    setFound()
  }

  render() {
    return (
      <Layout>
        <Helmet title={`Page not found – ${config.siteTitle}`} />
        <SEO />
        <section class="page_404">
          <div class="container">
            <div class="row">	
              <div class="col-sm-12 ">
                <div class="col-sm-10 col-sm-offset-1  text-center">
                  <div class="four_zero_four_bg">
                    <h1 class="text-center ">404</h1>
                  </div>
                
                  <div class="contant_box_404">
                    <h3 class="h2">
                      Look like you're lost
                    </h3>
                    
                    <p>what r u searching for ?</p>
                    
                    <a href="/" class="link_404">Go to Home</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}
